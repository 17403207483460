import PropTypes from "prop-types"
import { linkPropTypes, mediaPropTypes } from "../../../utils/types"
import Image from "next/image"
import CustomLink from "../custom-link"
import circleLogoUrl from "../../logos/circle-logo.svg?url"
//import CountrySelect from "../country-select/index-old"
import Input from "../input"
import Button from "../store-button"
import {BsFacebook, BsInstagram} from "react-icons/bs"
import { useState, useRef } from "react"

const Footer = ({ footer, locale }) => {

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null);

  // Prevent errors if no metadata was set
  if (!footer) {
    return null
  }
    
  async function submitEmailAddress(e) {
  console.log(`submitting email address ${email}`)
  e.preventDefault();

  // this is where your mailchimp request is made

const res = await fetch('/api/subscribe-user', {
    body: JSON.stringify({
      email: inputRef.current.value,
    }),

    headers: {
      'Content-Type': 'application/json',
    },

    method: 'POST',
  });

  console.log(res);
  }

  return (
    <footer className="pt-12 bg-sunrise mt-12">
      <div className="container flex-col lg:flex-row lg:justify-between w-100">
        {/* <div>
          {footer.logo && (
            <NextImage width="120" height="33" media={footer.logo}/>
          )}
        </div> */}
        <nav className="flex flex-wrap flex-row lg:gap-12 justify-between mb-10 max-w-[100%]">
          <div key={"column-email"} className="mt-10 lg:mt-0 w-auto box flex-row mx-4">
           
              <form onSubmit={submitEmailAddress}>
                {/* Your Email Address</label> */}
                <label htmlFor="list_email"><p className="tracking-wide font-cursive text-white text-xl">
               {"Join our mailing list and get access to exclusive discounts"}
            </p></label>
                <Input className="pr-8 w-full" 
                    type="email"
                    name="list_email" 
                    id="list_email"
                    autoComplete="email"
                    //autoCapitalize="off"
                    //autoCorrect="off"
                    //label="Email"
                    ref={inputRef}
                    required
                    placeholder="example@gmail.com"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant='primary' onClick={submitEmailAddress}>Subscribe</Button>
              </form>
          </div>
          {footer.columns.map((footerColumn) => (
            <div
              key={footerColumn.id}
              className="mt-10 lg:mt-0 w-6/12 lg:w-auto"
            >
              <p className="tracking-wide font-cursive text-white text-xl">
                {footerColumn.title}
              </p>
              <ul className="mt-2">
                {footerColumn.links.map((link) => (
                  <li key={link.id}
                    className="text-white py-1 px-1 -mx-1 hover:text-primary-900 text-lg">
                    <CustomLink link={link}>{link.text}</CustomLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div key={"logo_links"} className="mt-10 lg:mt-0 lg:w-auto box flex-row">
            <p className="tracking-wide font-cursive text-white text-xl flex-1">
               {"Follow Us"}
            </p>
            <ul className="list-none flex flex-row items-baseline flex-1">
            {/* <ul className="mt-2 list-none justify-between items-baseline hidden"> */}
              <li key="facebook-link" className="text-white hover:text-primary-900 text-lg pr-4">
                <CustomLink link={{id:'facebook_link', url:'https://facebook.com/brownedandtoasted', text: 'Find us on Facebook', newTab: true}}><BsFacebook/></CustomLink>
              </li>
              <li key="instagram-link" className="text-white hover:text-primary-900 text-lg">
                <CustomLink link={{id:'instagram_link', url:'https://instagram.com/brownedandtoasted', text: 'Follow us on Instagram', newTab: true}}><BsInstagram/></CustomLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="flex flex-row container w-100 items-center">
        <div className="flex flex-col w-3/4">
          <div className="text-sm bg-sunrise text-white justify-center items-center">
            <div className="">{footer.smalltext}</div>
          </div>
          
          <div className="text-sm bg-sunrise text-white justify-center items-center">
            {/* This should be footer.copyright */}
            <div className="">{'Copyright 2023 Browned and Toasted LLC'}</div>
          </div>
        </div>
        <div className="w-1/5 justify-center items-center">
            <Image className="grayscale invert mix-blend-screen pb-4"  
                width="65"  
                src={circleLogoUrl} 
                alt='Browned & Toasted 2023'
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"/>
        </div>
        {/* <div className="min-w-[256px] flex xsmall:justify-end"> */}
        <div className="xsmall:justify-end">
          {/* <CountrySelect locale={locale}/> */}
        </div>
       
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(linkPropTypes),
      })
    ),
    smalltext: PropTypes.string.isRequired,
  }),
}

export default Footer